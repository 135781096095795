<template>
  <Layout tituloPagina="Sistema | Ajustes | Ticket de pago">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Ajustes del ticket de pago
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  Actualizar
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Actualizar
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="$router.go(-1)"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-8">
                <h5>Plantilla del ticket de pago</h5>
              </div>
              <div class="col-md-4 text-right mb-3">
                <button class="btn btn-secondary btn-sm"
                  @click="generarPlantilla()">
                  Generar plantilla
                </button>
              </div>
            </div>
            <div class="mb-3">
              <textarea id="plantillaTicketPago" style="display: none;"></textarea>
            </div>
          </div>
          <div class="col-md-5">
            <h5>Variables disponibles</h5>
            <strong>Datos del logo: </strong> 
            $logo_ticket$
            <br>
            <strong>Datos de la empresa: </strong> 
            $nombre_empresa$
            <br>
            <strong>Datos del cliente: </strong>
            <template v-for="(campo, index) in campos">
              <span :key="index" v-if="true">
                {{campo.codigo}}
                <sup v-if="campo.alias != '' && campo.alias != null">
                  {{campo.alias}}
                </sup>
              </span>
              &nbsp;
            </template>
            <br>
            <strong>Datos del ticket de pago: </strong>
            $numero_ticket$, $fecha_registro$, $hora_registro$, $importe_recibido$, $metodo_pago$, 
            $facturas_pagadas$, $cajero$, $saldo_pendiente$, $facturas_pagadas_con_detalle$,
            $facturas_pagadas_con_detalle_2$, $saldo_total$
            <br><br>
            <h5>Tamaño de la impresión</h5>
            Escoge el tamaño que deseas para tus tickets de pago, la media es de <strong>{{ajustes.tamano_ticket}} centímetros</strong>
            <br><br>
            <Slider v-model="ajustes.tamano_ticket" :min="0" :max="30"/>
            <br><br>
            <h5>Logo del ticket de pago</h5>
            <div class="row">
              <div class="col-md-4 mb-3">
                <div id="marcoLogo">
                  <div
                    v-if="!imagen_logo && !ajustes.logo_ticket"
                    id="divBtnLogo"
                    class="text-center"
                    @click="seleccionarLogo()"
                  >
                    {{ !imagen_logo ? 'Seleccionar' : '' }}
                  </div>
                  <div v-if="imagen_logo && ajustes.logo_ticket">
                    <button
                      type="button"
                      @click="eliminarLogo()"
                      class="btnEliminarLogo btn btn-danger btn-sm btn-rounded"
                    >
                      <i class="mdi mdi-close-thick"></i>
                    </button>
                    <img
                      id="imagenLogo"
                      class="img-fluid rounded img-thumbnail"
                      :src="imagen_logo"
                      @click="visibleImagen = true"
                    />
                  </div>
                </div>
                <vue-easy-lightbox
                  :visible="visibleImagen"
                  :imgs="imagen_logo"
                  @hide="visibleImagen = false"
                ></vue-easy-lightbox>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div class="input-group">
                      <span class="input-group-text">
                        <span class="d-none d-sm-block">Width</span>
                        <span class="d-block d-sm-none">W</span>
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        v-model="ajustes.width_logo"
                        min="0"
                      />
                    </div>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div class="input-group">
                      <span class="input-group-text">
                        <span class="d-none d-sm-block">Height</span>
                        <span class="d-block d-sm-none">H</span>
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        v-model="ajustes.height_logo"
                        min="0"
                      />
                    </div>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="d-grid gap-2">
                      <button
                        class="btn bg-gradient"
                        :class="imagen_logo == null ? 'btn-soft-success' : 'btn-soft-danger'"
                        @click="imagen_logo == null ? seleccionarLogo() : eliminarLogo() "
                      >
                        <i class="mdi" :class="imagen_logo == null ? 'mdi-upload' : 'mdi-trash-can-outline'"></i>
                        {{imagen_logo == null ? 'Seleccionar' : 'Eliminar'}} logo de la empresa
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import SistemaSrv from '@/services/SistemaSrv.js'
import PlantillaSrv from '@/services/PlantillaSrv.js'
import VistasCampos from '@/services/VistasCampos.js'
import axios from 'axios'

const $ = require("jquery")
import "summernote/dist/summernote-lite.min.css"
import "summernote/dist/summernote-lite.min"
import Slider from "@vueform/slider"
import VueEasyLightbox from "vue-easy-lightbox"

import API from '@/API.js'

export default {
  name: 'TicketPago',
  components: { 
    Layout,
    Slider,
    VueEasyLightbox
  },
  data() {
    return {
      ajustes: {
        tamano_ticket: 4,
        logo_ticket: false,
        width_logo: 30,
        height_logo: 30
      },
      plantillas: {
        ticket_pago: {
          nombre: '',
          contenido: ''
        }
      },
      campos: [
        { campo: 'Nombre', alias: '', codigo: '$nombre$' },
        { campo: 'Apellido paterno', alias: '', codigo: '$apellido_paterno$' },
        { campo: 'Apellido materno', alias: '', codigo: '$apellido_materno$' },
        { campo: 'RFC', alias: '', codigo: '$rfc$' },
        { campo: 'Sexo', alias: '', codigo: '$sexo$' },
        { campo: 'Tipo de cliente', alias: '', codigo: '$tipo_cliente$' },
        { campo: 'Estado', alias: '', codigo: '$estado$' },
        { campo: 'Calle', alias: '', codigo: '$calle$' },
        { campo: 'N° ext', alias: '', codigo: '$numero_exterior$' },
        { campo: 'N° int', alias: '', codigo: '$numero_interior$' },
        { campo: 'Colonia', alias: '', codigo: '$colonia$' },
        { campo: 'Delegación o Municipio', alias: '', codigo: '$delegacion$' },
        { campo: 'Código postal', alias: '', codigo: '$codigo_postal$' },
        { campo: 'Estado de residencia', alias: '', codigo: '$estado_residencia$' },
        { campo: 'País', alias: '', codigo: '$pais$' },
        { campo: 'Correo electrónico', alias: '', codigo: '$correo_electronico$' }
      ],
      bandera_spinner: false,
      movil: false,

      imagen_logo: null,
      logo: null,
      visibleImagen: false,

      tk: localStorage.getItem('argusblack.token'),
      API: API,
    }
  },

  created: function(){
    var self = this

    if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i) ||
        window.innerWidth <= 780
    ) {
      self.movil = true
    }

    self.refrescarAjustes()
  },
  mounted() {
    let self = this

    $(document).ready(function() {
      var toolbar = [
        ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['height', ['height']],
        ['view', ['codeview', 'help']]
      ]

      if (self.movil) {
        toolbar = [
          ['style', ['style', 'bold', 'italic', 'underline']],
          ['fontsize', ['fontsize', 'color']],
          ['para', ['ul', 'paragraph']],
          ['table', ['table', 'height']],
          ['view', ['codeview']]
        ]
      }

      $('#plantillaTicketPago').summernote({
        height: 600,
        callbacks: {
          onChange: function(contents, $editable) {
            self.plantillas.ticket_pago.contenido = contents
          }
        },
        toolbar: toolbar
      })
      $('.note-editable').css('background-color', '#FFFFFF')
    })

    setTimeout(function(){
      self.refrescarPlantilla()
      self.refrescarCampos()
    }, 450)
  },

  methods: {
    actualizar() {
      let self = this,
        ajustes = Object.assign({}, self.ajustes)

      self.bandera_spinner = true
      delete ajustes.logo_ticket

      // Actualización de los ajustes
      let prom1 = SistemaSrv.actualizar(ajustes)

      // Actualización de la plantilla del ticket de pago
      let prom2 = PlantillaSrv.actualizar('ticket_pago', self.plantillas.ticket_pago.contenido)

      axios.all([prom1, prom2]).then(response => {
        iu.msg.success('Se actualizaron los datos')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron actualizar los datos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    generarPlantilla: function() {
      let self = this
      PlantillaSrv.plantillaPorDefectoJSON('ticket_pago').then(response => {
        let ticketPago = response.data
        Object.assign(self.plantillas.ticket_pago, ticketPago)

        $('#plantillaTicketPago').summernote('code',ticketPago.contenido)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la plantilla ticket_pago por defecto'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    refrescarCampos () {
      let self = this

      VistasCampos.vistasCamposJSON().then(response => {
        let campos = response.data
        campos = campos.filter(campo => {
          return campo.id_vista == 36
        })

        campos.forEach( campo1 => {
          let index = self.campos.findIndex(campo2 => campo1.campo == campo2.campo)
          if(index == -1) return
          self.campos[index].alias = campo1.alias
        })
      })
    },

    refrescarAjustes() {
      let self = this

      SistemaSrv.ajustes(["tamano_ticket", "logo_ticket", "width_logo", "height_logo"]).then(response => {
        let ajustes = response.data
        Object.assign(self.ajustes, ajustes)
        self.ajustes.logo_ticket = ajustes.logo_ticket == "0" ? false : true

        if(self.ajustes.logo_ticket)
          self.imagen_logo = self.API + '/sistema/tickets-pagos/logo?_tk=' + self.tk + '&rnd=' + Math.random()
      })
    },

    refrescarPlantilla: function() {
      let self = this
      
      PlantillaSrv.plantillaJSON('ticket_pago').then(response => {
        let ticketPago = response.data
        Object.assign(self.plantillas.ticket_pago, ticketPago)
        $('#plantillaTicketPago').summernote('code',ticketPago.contenido)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la plantilla de ticket de pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    actualizarLogo: function() {
      var self = this,
        datos = {
          logo: self.logo,
          eliminar_logo : self.ajustes.logo_ticket
        }

      SistemaSrv.actualizarLogo(datos).then(response => {
        iu.msg.success("Logo guardado con exito")
        self.refrescarAjustes()
      }).catch(error => {
        let mensaje
        try {
          mensaje.error.response.data.message
        } catch (e) {
          mensaje = 'No se puede actualizar el logo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    seleccionarLogo: function(){
      let self = this

      var inpLogo = document.createElement("input")
      inpLogo.setAttribute("type", "file")
      var reader = new FileReader()

      inpLogo.click()

      reader.onload = function(e) {
        self.imagen_logo = this.result

        var img = new Image();
        img.src = this.result;
        img.onload = function () {
          var tam_ticket = ((self.ajustes.tamano_ticket * 38) - 20).toFixed(0)
          self.ajustes.width_logo = tam_ticket

          if(img.width == img.height) self.ajustes.height_logo = tam_ticket
          else {
            let scala = tam_ticket / img.width
            self.ajustes.height_logo = (img.height * scala).toFixed(0)
          }

          self.actualizar()
        }
      }

      inpLogo.addEventListener("change", function() {
        reader.readAsDataURL(inpLogo.files[0])
        self.ajustes.logo_ticket = true
        self.logo = inpLogo.files[0]
        self.actualizarLogo()
      })
    },

    eliminarLogo: function() {
      var self = this

      SistemaSrv.eliminarLogo().then(response => {
        iu.msg.success("Logo eliminado con exito")
        self.ajustes.logo_ticket = false
        self.imagen_logo = null
        self.logo = null
        self.refrescarAjustes()
      }).catch(error => {
        let mensaje
        try {
          mensaje.error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo eliminar el logo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
#imagenLogo {
  width: 100%;
  height: 100%;
}
#marcoLogo {
  border: thin solid #ccc;
  border-radius: 0.75rem;
  background-color: #fafafa;
  width: 100%;
  min-height: 100px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
#divBtnLogo {
  width: 100%;
  height: 170px;
  line-height: 170px;
}
.btnEliminarLogo {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 100;
}
</style>